import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'

import PageTemplate from '../components/pageTemplate'
import Crate from "../components/crate"
import MainContainer from "../components/mainContainer"
import Image404 from "../assets/404.png"
import { navigate } from "gatsby"


class Page404 extends React.Component {

  navigateHome = (event) => {
    navigate('/')
  }

  render() {

    const locale = this.props.pageContext.locale;
    const title = "404";

    return (
      <PageTemplate locale={locale} title={title} icon={"icon-a"} headerImage={this.props.data.headerImage}>
        <Crate className="bg-white">
          <MainContainer className="px-5 sm:px-0 py-16">
            <div className="flex w-full leading-none justify-center items-center text-blue-900 tk-harfang-pro" style={{ fontSize: '12rem' }}>
              <span>4</span>
              <img className="inline-block" style={{ width: '150px', height: '150px' }} src={Image404} alt="" />
              <span>4</span>
            </div>
            <div className="w-full text-center">
              <p className="mt-0 mb-10">
                <span className="block leading-snug text-blue-900 tk-harfang-pro text-mlg"><FormattedMessage id="Page Not Found" /></span>
                <FormattedMessage id="It looks like youre lost" />
              </p>
              <p>
                <span onClick={this.navigateHome}>
                  <a href="/" className="bg-blue-900 hover:bg-blue-700 text-white font-bold px-4 py-2 rounded-full focus:outline-none">
                    <FormattedMessage id="Back to Home" />
                  </a>
                </span>
              </p>
            </div>
          </MainContainer>
        </Crate>
      </PageTemplate>
    )
  }
}

export default Page404

export const query = graphql`
  {
    headerImage: file(relativePath: { eq: "jesedu-global-icon-white.png" }) {
      childImageSharp {
        fixed(width: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`